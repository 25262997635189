import React from 'react';
import {Locale, supportedLocales, defaultLocale} from './localizations/LocalizationModule';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import LocalizedApp from './components/Layout/LocalizedApp';
import FIDO2Registration from './views/FIDO2/FIDO2Registration';
import RegCode from './views/RegCode/RegCode';
import NotFound from './views/Common/NotFound';
import Logout from './views/Common/Logout';
import PinRegistration from './views/Pin/PinRegistration';
import TacRegistration from './views/Tac/TacRegistration';
import AuthenticationLandingPage from './views/Common/AuthenticationLandingPage';
import AuthenticationErrorPage from './views/Common/AuthenticationErrorPage';
import RegistrationLandingPage from './views/Common/RegistrationLandingPage';
import FIDO2Authentication from './views/FIDO2/FIDO2Authentication';
import TacAndPinRegistration from './views/Tac/TacAndPinRegistration';
import TacAndPinAuthentication from './views/Tac/TacAndPinAuthentication';
import {VALID_SUBSCRIBER_IDS} from './utils/constants';
import './App.css';
import RegistrationErrorPage from './views/Common/RegistrationErrorPage';

const App = () => {
  const generateLocalizedRoutes = (locale: Locale | undefined) => {
    const homepagePath = locale ? `/${locale}` : ``;
    return (
      <Route
        key={locale ? locale : 'default'}
        path={homepagePath}
        element={<LocalizedApp
          locale={locale ? locale : defaultLocale}
          homepagePath={homepagePath}/>}
      >
        {VALID_SUBSCRIBER_IDS.map((subscriberId) => (
          <Route
            key="subscriber-parent-route"
            path={`subscriber/${subscriberId}`}
          >
            <Route
              index={true}
              key="registration-landing-page"
              element={<RegistrationLandingPage subscriberId={subscriberId}/>}
            />
            <Route
              key="pin-route"
              path='self-service/pin'
              element={<PinRegistration/>}
            />
            <Route
              key="tac-route"
              path='self-service/tac'
              element={<TacRegistration/>}
            />
            <Route
              key="tac-and-pin-route"
              path='self-service/tacAndPin'
              element={<TacAndPinRegistration/>}
            />
          </Route>
        ))}
        <Route
          key="registration-parent-route"
          path={`registration`}
        >
          <Route
            index={true}
            key="registration-route"
            element={<FIDO2Registration/>}
          />
          <Route
            key="registration-error-route"
            path="error"
            element={<RegistrationErrorPage />}
          />
          <Route
            key="registration-logout-route"
            path={`logout`}
            element={<Logout operation="registration"/>}
          />
        </Route>
        <Route
          key="authentication-parent-route"
          path="authentication"
        >
          <Route
            index={true}
            key="authentication-landing-page"
            element={<AuthenticationLandingPage/>}
          />
          <Route
            key="tac-authentication-route"
            path='tac'
            element={<TacAndPinAuthentication/>}
          />
          <Route
            key="authentication-route"
            path="fido2"
            element={<FIDO2Authentication/>}
          />
          <Route
            key="authentication-logout-route"
            path="logout"
            element={<Logout operation="authentication"/>}
          />
          <Route
            key="authentication-error-route"
            path="error"
            element={<AuthenticationErrorPage />}
          />
        </Route>
        <Route
          key="regcode-validation-route"
          path="regcode"
          element={<RegCode />}>
        </Route>
        <Route
          key="unmatched-route"
          path="*"
          element={<NotFound />}
          index
        />
      </Route>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        {[
          ...supportedLocales.map((key) => generateLocalizedRoutes(key)),
          generateLocalizedRoutes(undefined), // generate routes without locale param
        ]}
      </Routes>
    </BrowserRouter>
  );
};
export default App;
