import React, {useEffect, useState} from 'react';
import {fetchAuthenticationCsrfToken} from '../../handlers/Common/CsrfHandlers';
import TacAndPinInput from '../../components/TacAndPinInput/TacAndPinInput';
import {handleTacAndPinAuthentication} from '../../handlers/TacAndPin/TacAndPinAuthHandlers';
import {fetchIdentityPublicKey} from '../../handlers/Common/EncryptionPinHandlers';
import InternalServerError from '../../components/Alerts/InternalServerError';
import {isLocalEnv} from '../../utils/envHelper';
import {isMobile} from 'react-device-detect';
import {applyDensity, Density} from '@amzn/awsui-global-styles';
import {useLocation, useNavigate} from 'react-router';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';

const TacAndPinAuthentication = () => {
  const bundle = useAuthgardBundle();
  const navigate = useNavigate();
  const location = useLocation();
  const [csrfToken, setCsrfToken] = useState<string>(location.state?.csrfToken);
  const [publicKey, setPublicKey] = useState<CryptoKey>();
  const [serverError, setServerError] = useState<boolean>(false);
  const requestId = new URLSearchParams(window.location.search).get('request_id') || location.state?.requestId;

  useEffect(() => {
    document.title = bundle.getMessage('authentication_document_title');

    if (!isLocalEnv) {
      handlePublicKey();
      handleCsrf();
    }

    if (isMobile) {
      applyDensity(Density.Compact);
    }
  }, []);

  const handleCsrf = async () => {
    const token = await fetchAuthenticationCsrfToken();
    if (token != '') {
      setCsrfToken(token);
    } else {
      setServerError(true);
    }
  };

  const handlePublicKey = async () => {
    const key = await fetchIdentityPublicKey();
    if (key != undefined) {
      setPublicKey(key);
    } else {
      setServerError(true);
    }
  };

  const onBack = () => {
    navigate('/authentication/', {state: {requestId: requestId, csrfToken: csrfToken}});
  };

  const onSubmit = (pin: string, tac: string) => {
    return handleTacAndPinAuthentication(pin, tac, csrfToken, publicKey);
  };

  return (
    <div>
      {serverError ? <InternalServerError/> : <TacAndPinInput onSubmit={onSubmit} onBack={onBack}/>}
    </div>
  );
};

export default TacAndPinAuthentication;
